<template>
  <div class="login__wrapper">
    <div class="container container--fluid container--full">
      <section
        class="container--sm container-fluid--sm sm-oculto"
        style="background-color: var(--purple-color--300)"
      >
        <div class="formOnboarding">
          <div class="formOnboarding__top">
            <svg class="logotipo">
              <use xlink:href="#logo-frontliner"></use>
            </svg>
          </div>
          <div class="formOnboarding__body">
            <figure>
              <img
                src="@/assets/img/img_step-1.png"
                alt="step 1"
                style="width: 100%"
              />
              <figcaption>
                <h3>Traslado Seguro!</h3>
                <span
                  >Con Frontliner tu paquete llega a tus manos más rápido.
                  Puedes recibir todos tus envios y paquetes de forma segura y
                  puntual.</span
                >
              </figcaption>
            </figure>
          </div>
        </div>
      </section>
      <section class="container--fluid container-fluid--sm">
        <!-- form header -->
        <header class="form__header">
          <div class="header__inner">
            <div class="header__row">
              <!-- header__left -->
              <div class="header__left">
                <div class="header__logotipo__sm">
                  <svg class="logotipo">
                    <use xlink:href="#logo-frontliner"></use>
                  </svg>
                </div>
              </div>
              <!-- header__right -->
              <div class="header__right">
                <div class="header__tools">
                  <span class="header__notes header__tools-item"
                    >¿Ya tienes una cuenta?
                    <router-link :to="{ name: 'login' }"
                      >Inicia Sesión</router-link
                    >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
        <!-- form main -->
        <div class="form__main">
          <div class="form__custom">
            <form
              class="form-wizard js-form-wizard"
              id="form_wizard_1"
              @submit="register"
            >
              <!-- 1er step -->
              <fieldset
                :class="'form__step js-step' + (step === 1 ? ' is-active' : '')"
              >
                <!-- titulo por step -->
                <div class="form__top">
                  <h2 class="form__title">Bienvenido!</h2>
                  <p class="form__text">
                    Conviértete en miembro, disfrutarás de ofertas exclusivas o
                    todos nuestros beneficios.
                  </p>
                </div>
                <!-- nombres y apellidos -->
                <div class="form_grup--grid">
                  <div class="input-group js-input-group">
                    <label for="nombres">Nombres <span>*</span></label>
                    <input
                      class="form-control js-form-control"
                      type="text"
                      name="nombres"
                      id="nombres"
                      placeholder="Tus nombres"
                      required
                    />
                  </div>
                  <div class="input-group js-input-group">
                    <label for="apellidos">Apellidos <span>*</span></label>
                    <input
                      class="form-control js-form-control"
                      type="text"
                      name="apellidos"
                      id="apellidos"
                      placeholder="Tus apellidos"
                      required
                    />
                  </div>
                </div>
                <!-- fecha de nacimiento -->
                <div class="input-group js-input-group">
                  <label for="nacimiento"
                    >Fecha de nacimiento <span>*</span></label
                  >
                  <input
                    class="form-control js-form-control"
                    type="date"
                    name="nacimiento"
                    id="nacimiento"
                    required
                  />
                </div>
                <!-- correo electronico -->
                <div class="input-group js-input-group">
                  <label for="email">Correo Electrónico <span>*</span></label>
                  <input
                    class="form-control js-form-control"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="tu-correo@email.com"
                    required
                  />
                </div>
                <!-- contraseña -->
                <div class="input-group js-input-group">
                  <label for="password"
                    >Crear contraseña para su perfil <span>*</span></label
                  >
                  <div class="field">
                    <input
                      class="form-control js-form-control"
                      type="password"
                      @onkeyup="passwordKeyup()"
                      id="pass"
                      placeholder="Al menos 8 carácteres"
                      required
                    />
                    <span class="verOculto">
                      <svg class="icon" onClick="showPass()">
                        <use xlink:href="#icon-eyes-slash" id="icon"></use>
                      </svg>
                    </span>
                  </div>
                  <div class="indicador">
                    <span class="debil"></span>
                    <span class="medio"></span>
                    <span class="fuerte"></span>
                  </div>
                  <span class="texto"></span>
                </div>
                <!-- confirmar contraseña -->
                <div class="input-group js-input-group">
                  <label for="password"
                    >Confirmar contraseña<span>*</span></label
                  >
                  <div class="field">
                    <input
                      class="form-control js-form-control"
                      type="password"
                      id="confirmpass"
                      placeholder="Al menos 8 carácteres"
                      required
                    />
                    <span class="verOculto">
                      <svg class="icon" onClick="showPass()">
                        <use xlink:href="#icon-eyes-slash"></use>
                      </svg>
                    </span>
                  </div>
                </div>
              </fieldset>
              <!-- 2do step -->
              <fieldset
                :class="'form__step js-step' + (step === 2 ? ' is-active' : '')"
              >
                <!-- titulo por step -->
                <div class="form__top">
                  <h2 class="form__title">Datos personales</h2>
                  <p class="form__text">
                    Información necesaria para el correcto embarque de sus
                    paquetes.
                  </p>
                </div>
                <!-- nacionalidad -->
                <div class="input-group js-input-group">
                  <label for="pais">Nacionalidad <span>*</span></label>
                  <select
                    class="
                      form-control
                      js-form-control
                      form__custom--select
                      -select
                    "
                    name="pais"
                  >
                    <option value="" disabled selected>
                      - Selecciona tu País -
                    </option>
                    <option value="Afganistan">Afganistán</option>
                    <option value="Afganistan">Afganistán</option>
                    <option value="Afganistan">Afganistán</option>
                    <option value="Afganistan">Afganistán</option>
                    <option value="Afganistan">Afganistán</option>
                  </select>
                </div>
                <!-- Documento o pasaporte -->
                <div class="input-group js-input-group">
                  <label for="document"
                    >Numero de documento o Pasaporte <span>*</span></label
                  >
                  <input
                    class="form-control js-form-control"
                    type="text"
                    name="document"
                    id="document"
                    placeholder="0.000.000"
                    required
                  />
                </div>
                <!-- ciudad -->
                <div class="input-group js-input-group">
                  <label for="ciudad">Ciudad <span>*</span></label>
                  <input
                    class="form-control js-form-control"
                    type="text"
                    name="ciudad"
                    id="ciudad"
                    placeholder="Donde vives"
                    required
                  />
                </div>
                <!-- direccion -->
                <div class="input-group js-input-group">
                  <label for="direccion">Dirección <span>*</span></label>
                  <input
                    class="form-control js-form-control"
                    type="text"
                    name="direccion"
                    id="direccion"
                    placeholder="Calles de su domicilio"
                    required
                  />
                </div>
                <!-- telefono -->
                <div class="input-group js-input-group">
                  <label for="telefono"
                    >Teléfono o Celular <span>*</span></label
                  >
                  <input
                    class="form-control js-form-control"
                    type="text"
                    name="telefono"
                    id="telefono"
                    placeholder="+595 --- ---"
                    required
                  />
                </div>
              </fieldset>
              <!-- 3er step -->
              <fieldset
                :class="'form__step js-step' + (step === 3 ? ' is-active' : '')"
              >
                <!-- titulo por step -->
                <div class="form__top">
                  <h2 class="form__title">Datos personales</h2>
                  <p class="form__text">
                    Información necesaria para el correcto embarque de sus
                    paquetes.
                  </p>
                </div>
                <!-- direccion -->
                <label>Agencia para el retiro de paquetes <span>*</span></label>

                <div class="form_grup--grid">
                  <div class="input-group js-input-group">
                    <input
                      id="casa-central"
                      type="radio"
                      name="status"
                      value="casa-central"
                    /><!--checked="true"-->
                    <label for="casa-central">
                      <span>Casa Central</span>
                    </label>
                  </div>
                  <div class="input-group js-input-group">
                    <input
                      id="suc-espana"
                      type="radio"
                      name="status"
                      value="suc-espana"
                    />
                    <label for="suc-espana">
                      <span>Sucursal España</span>
                    </label>
                  </div>
                  <div class="input-group js-input-group">
                    <input
                      id="suc-carmelita"
                      type="radio"
                      name="status"
                      value="suc-carmelita"
                    />
                    <label for="suc-carmelita">
                      <span>Sucursal Carmelita</span>
                    </label>
                  </div>
                  <div class="input-group js-input-group">
                    <input
                      id="suc-fernando"
                      type="radio"
                      name="status"
                      value="suc-fernando"
                    />
                    <label for="suc-fernando">
                      <span>Fernando de la Mora</span>
                    </label>
                  </div>
                </div>

                <!-- mostramos direccion de la sucursal seleccionada -->
                <span
                  class="sucusal__seleccionado"
                  id="dir-seleccionado"
                ></span>
                <!-- adjuntar documento -->
                <div class="input-group js-input-group">
                  <label for="file-documento"
                    >Adjuntar foto de tu documento o Pasaporte
                    <span>*</span></label
                  >
                  <input
                    class="form-control js-form-control"
                    type="file"
                    name="file-documento"
                    id="file-documento"
                    placeholder="0.000.000"
                    required
                  /><!--hidden-->
                </div>
              </fieldset>
              <!-- button group -->
              <div class="btn__group input-group">
                <button
                  :class="'btn btn--clear btn-prev js-btn-prev' + (step > 1 ? ' is-active' : '')"
                  type="button"
                  name="button"
                  @click="prevStep"
                >
                  <svg class="icon">
                    <use xlink:href="#icon-arrow-left"></use>
                  </svg>
                  <span>Anterior</span>
                </button>
                <button
                  class="btn btn--success js-btn-next"
                  type="button"
                  data-step-text="Siguiente"
                  data-final-step-text="CONFIRMAR"
                  name="button"
                  @click="nextStep"
                >
                  <span>Siguiente</span>
                  <svg class="icon">
                    <use xlink:href="#icon-arrow-right"></use>
                  </svg>
                </button>
              </div>

              <div class="progress-bar js-progress-bar"></div>
            </form>
          </div>
        </div>
        <!-- form footer -->
        <footer class="form__footer">
          <div class="footer__inner">
            <div class="footer__row">
              <!-- footer__left -->
              <div class="footer__left">
                <a href="">¿Crear una cuenta para Empresa?</a>
              </div>
              <!-- footer__right -->
              <div class="footer__right">
                <div class="footer__tools">
                  <p>
                    Copyright &copy;
                    {{ year }}
                    - Fronliner.com.py
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </div>
  </div>
</template>

<style scoped>
@import "../assets/css/estilos.css";
</style>

<script>
// import { mapActions } from "vuex";
// import axios from "axios";

export default {
  name: "register",
  data() {
    return {
      user: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      processing: false,
      step: 1,
      year: 2022
    };
  },
  methods: {
    // ...mapActions({
    //   signIn: "auth/login",
    // }),
    prevStep() {
      this.step = this.step - 1;
    },
    nextStep() {
      if(this.step < 3){
        this.step = this.step + 1;
      }else{
        this.processing = true;
      }
    },
    register() {
      alert('hola mundo');
      switch (this.step) {
        case 1:
          this.step = 2;
          break;
        case 2:
          this.step = 3;
          break;
        case 3:
          this.processing = true;
          // await axios
          //   .post("/register", this.user)
          //   .then((response) => {
          //     console.log(response);
          //     this.signIn();
          //   })
          //   .catch(({ response: { data } }) => {
          //     alert(data.message);
          //   })
          //   .finally(() => {
          //     this.processing = false;
          //   });
          break;
        default:
          break;
      }
    },
  },
};
</script>